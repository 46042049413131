import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import axios from "../../axios/index";
import debounce from "lodash/debounce";
import ScreenLoader from "../../component/screenLoader";
import SearchField from "../../component/searchField";
import Declined from "../../component/modal/declined";
import Accepted from "../../component/modal/accepted";
export default function RequestBikes() {
  const columns = [
    { field: "id", headerName: "NO", flex: 0 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "bikes", headerName: "Bikes", flex: 2 },
    { field: "size", headerName: "Size", flex: 0 },
    { field: "date", headerName: "Date", flex: 2 },
    { field: "time", headerName: "Time", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.status == "pending") {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Declined id={params.row.id} setRows={setRows} />
              <Accepted id={params.row.id} setRows={setRows} />
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color:
                  params?.row?.status == "accepted" ? "#00B232" : "#EA4D3D",
              }}
              component="p"
            >
              {params?.row?.status}
            </Typography>
          );
        }
      },
    },
  ];

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(""); // State for search query
  const [loader, setLoader] = React.useState(true);

  var fetchData = async (searchText = "") => {
    axios
      .get(
        `/bike-requests?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchText}`
      )
      .then((res) => {
        // Transform the received data into the desired format
        const transformedData = res?.data?.data?.map((item) => ({
          id: item._id,
          name: `${item?.user[0]?.firstName} ${item?.user[0]?.lastName}`,
          bikes: item?.bike[0]?.name,
          size: item?.size[0]?.variant,
          date: new Date(item?.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          time: new Date(item?.createdAt).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
          status: item?.status,
        }));

        setRows(transformedData);
        setTotalRows(res?.data?.totalRecords);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage?.page);
  };

  // Define a debounce delay (e.g., 300 milliseconds)
  const debounceDelay = 300;

  // Create a debounced search function
  const debouncedSearch = debounce((searchText = "") => {
    fetchData(searchText);
  }, debounceDelay);

  // Handle search input change
  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setPage(0); // Reset to the first page when searching
    setSearchQuery(searchText);
    if (!searchText || searchText == "") {
      debouncedSearch();
    } else if (searchText.length >= 3) {
      debouncedSearch(searchText);
    }
  };

  // Initial data fetch
  React.useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  if (loader) {
    return <ScreenLoader />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "198px",
        pb: "150px",
        pl: "24px",
        pr: "60px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Bike Requests
      </Typography>
      <div style={{ width: "100%", height: 400 }}>
        <div style={{ marginBottom: "16px" }}>
          <SearchField value={searchQuery} onChange={handleSearchChange} />
        </div>
        <DataGrid
          sx={{
            borderRadius: "20px",
            ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#2F2F2F",
            },
            ".css-19p8xgs-MuiDataGrid-root .MuiDataGrid-cellContent": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#1F1F1F",
            },
            ".MuiDataGrid-withBorderColor": {
              pl: "10px",
            },
            ".MuiDataGrid-cell": {
              pl: "20px !important",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: pageSize },
            },
          }}
          rows={rows?.length ? rows : []}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={[10]}
        />
      </div>
    </Box>
  );
}
