import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputField from "../../component/inputField/inputField";
import axios from "../../axios/index";
import ScreenLoader from "../../component/screenLoader";
import { toast } from "react-toastify";
import TextEditor from "../../component/textEditor";

const Special = () => {
  const [special, setSpecial] = useState("");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!special) {
      setLoader(true);
      axios
        .get("/get-special")
        .then((res) => setSpecial(res?.data[0].text))
        .catch(() => {})
        .finally(() => setLoader(false));
    }
  }, []);

  const updateSpecial = () => {
    if (!special) {
      toast.error("Please fill required field!");
    }
    setDisabled(true);
    axios
      .post("/add-update-special", {
        text: special,
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Successfully Updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  if (loader) {
    return <ScreenLoader />;
  }

  return (
    <Box
      sx={{
        pt: "198px",
        pl: "40px",
        pr: "45px",
        background: "#fff",
        minHeight: "100vh",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Update Specials
      </Typography>
      <TextEditor
        onChange={setSpecial}
        value={special}
        placeholder="Write text...."
        multiline={true}
        rows={10}
      />
      {/* <InputField
        onChange={(e) => setSpecial(e.target.value)}
        value={special}
        placeholder="Write text...."
        multiline={true}
        rows={10}
      /> */}
      <Box textAlign="right" mt={2}>
        <Button
          onClick={() => updateSpecial()}
          type="submit"
          sx={{
            borderRadius: "10px",
            fontSize: "16px",
            textTransform: "capitalize",
            fontWeight: 400,
          }}
          variant="contained"
        >
          {disabled ? (
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          ) : (
            "Update"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default Special;
