import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import GryDot from "../../assets/gryDot.png";
import OrangeDot from "../../assets/orangeDot.png";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function IconCheckboxes({ data, label, field }) {
  return (
    <Box>
      <Typography
        sx={{
          textAlign: "start",
          color: "rgba(0, 0, 0, 0.87)",
          fontSize: "20px",
          fontWeight: "400",
          mb: "10px",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {data?.map((itm, ind) => {
          return (
            <Box
              key={ind}
              sx={{
                border: "1px solid #EDEDED",
                background: "#fff",
                borderRadius: "18px",
                padding: "0 10px",
                mr: "5px",
              }}
            >
              <FormControlLabel
                control={
                  // <Checkbox
                  //   checked={field.value.includes(itm._id)} // Check if the item's ID is in the sizes array
                  //   value={itm._id}
                  //   icon={<img src={GryDot} />}
                  //   checkedIcon={<img src={OrangeDot} />}
                  //   name={`sizes[${ind}]`}
                  //   onChange={(e) => {
                  //     const newValue = e.target.value;
                  //     const updatedSizes = [...field.value];
                  //     if (e.target.checked) {
                  //       updatedSizes.push(newValue);
                  //     } else {
                  //       const indexToRemove = updatedSizes.indexOf(newValue);
                  //       if (indexToRemove !== -1) {
                  //         updatedSizes.splice(indexToRemove, 1);
                  //       }
                  //     }
                  //     field.onChange({
                  //       target: { name: field.name, value: updatedSizes },
                  //     });
                  //   }}
                  // />
                  <Radio
                    checked={field.value.includes(itm._id)} // Check if the item's ID matches the selected value
                    value={itm._id}
                    icon={<img src={GryDot} />}
                    checkedIcon={<img src={OrangeDot} />}
                    name={field.name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      field.onChange({
                        target: { name: field.name, value: newValue },
                      });
                    }}
                  />
                }
                label={itm.variant}
              />
            </Box>
          );
        })}
      </Box>
      {field.error &&
        field.touched && ( // Display error message for "sizes"
          <Typography
            component="p"
            sx={{
              color: "red",
              fontSize: ".8rem",
              textAlign: "left",
              mr: "20px",
            }}
          >
            {field.error}
          </Typography>
        )}
    </Box>
  );
}
