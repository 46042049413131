import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../axios/index";
import AddBikeModal from "../../component/modal/addBikeModal";
import CustomizedMenus from "../../component/menu";
import RemoveBikeModal from "../../component/removeBikeModal";
import debounce from "lodash/debounce";
import ScreenLoader from "../../component/screenLoader";
import SearchField from "../../component/searchField";

const btnStyle = {
  fontSize: "16px",
  fontWeight: "400",
  background: "transparent",
  color: "#000000",
  justifyContent: "start",
  padding: "0px 16px",
  boxShadow: "none",
  opacity: "0.8",
  height: "auto",
  // border: " 1px solid",
  borderRadius: "0",
  "&:hover": {
    background: "transparent",
    boxShadow: "none",
  },
};

export default function AddBike() {
  const [bikeSizes, setBikeSizes] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(""); // State for search query
  const [loader, setLoader] = React.useState(true);

  const columns = [
    { field: "id", headerName: "No", flex: 0 },
    { field: "bike", headerName: "Bike", flex: 2 },
    { field: "size", headerName: "Size", flex: 2 },
    // { field: "sizeId", headerName: "Size", flex: 2, hide: true },
    { field: "inStock", headerName: "In Stock", flex: 0 },
    {
      field: "availability",
      headerName: "Availability",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "24px",
              height: "24px",
              background: params?.row?.availability ? "#00B232" : "#EA4D3D",
              borderRadius: "50px",
            }}
          ></Box>
        );
      },
    },
    {
      field: "picture",
      headerName: "Picture",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "61px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={`https://admin.raysmtb.com/api/get-file/${params?.row?.picture}`}
                width="100%"
                height="100%"
                alt=""
              />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const options = [
          {
            label: (
              <AddBikeModal
                btnText="Edit"
                width="100%"
                btnStyle={btnStyle}
                params={params?.row}
                data={bikeSizes}
                setRows={setRows}
                id={params?.row?.id}
              />
            ),
            cPadding: "0px",
          },
          {
            label: (
              <RemoveBikeModal
                btnText="Remove"
                width="100%"
                btnStyle={btnStyle}
                params={params?.row}
                setRows={setRows}
                id={params?.row?.id}
              />
            ),
            cPadding: "0px",
          },
        ];
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CustomizedMenus options={options} />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    axios
      .get("/get-sizes")
      .then((res) => {
        setBikeSizes(res.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  const fetchData = async (searchText = "") => {
    axios
      .get(
        `/get-bikes?page=${page + 1}&pageSize=${pageSize}&search=${searchText}`
      )
      .then((res) => {
        // Transform the received data into the desired format
        const transformedData = res?.data?.data?.map((item) => ({
          id: item._id,
          bike: item?.name,
          size: item?.sizes?.map((itm) => itm.variant),
          sizeId: item?.sizes?.map((itm) => itm._id),
          inStock: item?.inStock,
          availability: item.availability,
          picture: item?.images[0]?.filename,
        }));

        setRows(transformedData);
        setTotalRows(res?.data?.totalRecords);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage?.page);
  };

  // Define a debounce delay (e.g., 300 milliseconds)
  const debounceDelay = 300;

  // Create a debounced search function
  const debouncedSearch = debounce((searchText = "") => {
    fetchData(searchText);
  }, debounceDelay);

  // Handle search input change
  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setPage(0); // Reset to the first page when searching
    setSearchQuery(searchText);
    if (!searchText || searchText == "") {
      debouncedSearch();
    } else if (searchText.length >= 3) {
      debouncedSearch(searchText);
    }
  };

  // Initial data fetch
  React.useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  if (loader) {
    return <ScreenLoader />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "198px",
        pb: "150px",
        pl: "40px",
        pr: "45px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Add Bikes
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "30px",
        }}
      >
        <Box
          sx={{
            mr: "20px",
          }}
        >
          <SearchField value={searchQuery} onChange={handleSearchChange} />
        </Box>
        <AddBikeModal data={bikeSizes} setRows={setRows} />
      </Box>
      <Box sx={{ width: "100%", height: 400 }}>
        <DataGrid
          sx={{
            borderRadius: "20px",
            ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#2F2F2F",
            },
            ".css-19p8xgs-MuiDataGrid-root .MuiDataGrid-cellContent": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#1F1F1F",
            },
            ".MuiDataGrid-withBorderColor": {
              pl: "10px",
            },
            ".MuiDataGrid-cell": {
              pl: "20px !important",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: pageSize },
            },
          }}
          rows={rows?.length ? rows : []}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={[10]}
        />
      </Box>
    </Box>
  );
}
