import SideBarImg from "../../assets/bg-sidebar.png";
const drawerWidth = 281;
export const style = {
  mainSideBar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100vh",
    backgroundImage: `url(${SideBarImg})`,
  },
  logoBox: { textAlign: "center", mt: 5 },
  list: { alignItems: "center" },
  listItem: { color: "#fff", marginTop: "80px" },
  listItemBtn: { padding: "0 72px" },
  listItemIcon: { color: "#fff", minWidth: "auto", pr: 1 },
  listItemText: {
    ".css-1602z4f-MuiTypography-root": {
      fontSize: "21px",
    },
  },
  listItemSetting: { color: "#fff", marginBottom: "80px" },
  main: {
    display: "flex",
    ".css-hyum1k-MuiToolbar-root": {
      minHeight: "auto",
    },
  },
  appBar: {
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    background: "#F5F5F5",
    boxShadow: "none",
    ".css-hyum1k-MuiToolbar-root": {
      minHeight: "auto",
    },
    ml: { sm: `${drawerWidth}px` },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "42px",
    paddingBottom: "42px",
    paddingRight: "60px !important",
  },
  iconBtn: { mr: 2, display: { sm: "none" } },
  toolBarTextUN: { color: "#081225", fontSize: "32px" },
  navItemBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
  },
  serchInput: {
    ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "12.5px 14px",
    },
    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderRadius: "20px",
      borderColor: "#E0E0E0",
      height: "54px",
    },
  },
  notiBox: {
    width: "49px",
    height: "49px",
    border: "1px solid #E0E0E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
  },
  notiCountBox: {
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    background: "#EA4D3D",
    position: "absolute",
    top: "8px",
    right: "5px",
  },
  userIconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
    border: "1px solid #E0E0E0",
    borderRadius: "20px",
    padding: "10px",
  },
  userDetailsBox: { mr: 2 },
  userName: {
    fontSize: "14px",
    color: "#081225",
  },
  userRole: {
    fontSize: "14px",
    color: "#B3B3B3",
  },
  dropDownIcon: { cursor: "pointer" },
};
