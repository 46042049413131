import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputField from "../inputField/inputField";
import { Field, Form, Formik } from "formik";
import { addBikeValidation } from "../../validations";
import { Box } from "@mui/system";
// import { style } from "../../pages/signin/style";
import {
  CircularProgress,
  // FormControlLabel,
  // Grid,
  // Radio,
  // TextField,
} from "@mui/material";
// import RadioGroupBtn from "../radio";
import IconCheckboxes from "../checkBox";
import CustomizedSwitches from "../toggelSwitch";
// import { style } from "../inputField/style";
import uploadIcon from "../../assets/upload.png";
import { useState } from "react";
import axios from "../../axios/index";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "500px",
    minWidth: "500px",
    borderRadius: "10px",
  },
  // "& .MuiDialogContent-root":{
  //   padding:"30px"
  // }
}));

const AddBikeModal = ({
  btnText,
  data,
  width,
  btnStyle,
  params,
  setRows,
  id,
}) => {
  const [open, setOpen] = React.useState(false);
  const [imagePreview, setImagePreview] = useState(
    params?.picture
      ? `https://admin.raysmtb.com/api/get-file/${params?.picture}`
      : null
  );
  const [loader, setLoader] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ width: width && width }}>
      <Button
        variant="contained"
        sx={{
          ...btnStyle,
          width: width && width,
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        {btnText ? btnText : "Add New Bike"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Edit Bike
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            // background:"rgb(0 0 0 / 4%)",
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            // color: "rgb(0 0 0 / 4%)",
            // "& :hover":{
            //     background:"#C1C1C1"
            // }
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent dividers> */}
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              bikeName: params?.bike ? params?.bike : "",
              sizes: params?.sizeId ? params?.sizeId : [],
              availability: params?.availability ? params?.availability : false,
              photo: params?.picture ? params?.picture : null,
              inStock: params?.inStock ? params?.inStock : "",
            }}
            validationSchema={addBikeValidation}
            onSubmit={(
              { bikeName, sizes, availability, photo, inStock },
              { resetForm }
            ) => {
              console.log("sizes", sizes);
              setLoader(true);
              const formData = new FormData();
              formData.append("name", bikeName);
              formData.append("availability", availability);
              formData.append("inStock", inStock);
              formData.append("image", photo);
              formData.append(`sizes[0]`, sizes);
              const requestMethod = params ? "put" : "post";
              const request = params
                ? "/edit-bike/" + params?.id
                : "/add-new-bike";
              axios[requestMethod](request, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
                .then((res) => {
                  if (res.status == 201 || res.status == 200) {
                    if (!params) {
                      const data = {
                        id: res.data._id,
                        bike: bikeName,
                        size: sizes,
                        sizeId: res.data.sizes,
                        inStock: res.data?.inStock,
                        availability: res.data.availability,
                        picture: `https://admin.raysmtb.com/api/get-file/${res?.data?.imgName}`,
                      };
                      setRows((prev) => [data, ...prev]);
                    } else {
                      setRows((prev) =>
                        prev.map((itm) => {
                          if (itm.id == id) {
                            return {
                              ...itm,
                              bike: bikeName,
                              size: sizes,
                              sizeId: res.data.sizes,
                              inStock: res.data?.inStock,
                              availability: res.data.availability,
                              picture: `https://admin.raysmtb.com/api/get-file/${res?.data?.imgName}`,
                            };
                          } else {
                            return itm;
                          }
                        })
                      );
                    }
                    toast.success(
                      `Bike Successfully ${params ? "Updated" : "Added"}`
                    );
                    resetForm();
                    handleClose();
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  setLoader(false);
                });
              // resetForm();
            }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Box sx={{ mb: "10px" }}>
                      <InputField
                        ml="0px"
                        label="Bike Name"
                        placeholder="Enter Bike Name"
                        mBottom
                        type="bikeName"
                        name="bikeName"
                        value={values["bikeName"]}
                        onChange={(e) => {
                          setFieldValue("bikeName", e.target.value);
                        }}
                      />
                      {errors["bikeName"] && touched["bikeName"] && (
                        <Typography
                          component="p"
                          sx={{
                            color: "red",
                            fontSize: ".8rem",
                            textAlign: "left",
                            mr: "20px",
                          }}
                        >
                          {errors["bikeName"]}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ mb: "10px" }}>
                      <InputField
                        ml="0px"
                        label="In Stock"
                        placeholder="Enter Stock"
                        mBottom
                        type="inStock"
                        name="inStock"
                        value={values["inStock"]}
                        onChange={(e) => {
                          setFieldValue("inStock", e.target.value);
                        }}
                      />
                      {errors["inStock"] && touched["inStock"] && (
                        <Typography
                          component="p"
                          sx={{
                            color: "red",
                            fontSize: ".8rem",
                            textAlign: "left",
                            mr: "20px",
                          }}
                        >
                          {errors["inStock"]}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        mb: "30px",
                        // background: "yellow",
                      }}
                    >
                      {/* <IconCheckboxes data={data} label="Available Size" /> */}
                      <Field name="sizes">
                        {({ field }) => (
                          <div>
                            <IconCheckboxes
                              data={data}
                              label="Available Size"
                              field={field}
                            />
                          </div>
                        )}
                      </Field>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Field name="availability">
                          {({ field }) => (
                            <div>
                              <CustomizedSwitches
                                field={field}
                                label="Availability :"
                                availability={params?.availability}
                              />
                            </div>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "400",
                              marginTop: "4px",
                            }}
                          >
                            Add Photo:
                          </Typography>
                          <Typography
                            htmlFor="upload"
                            component="label"
                            variant="label"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <input
                              hidden
                              type="file"
                              id="upload"
                              accept="image/*"
                              name="photo"
                              onChange={(e) => {
                                const selectedFile = e.currentTarget.files[0];
                                setFieldValue("photo", selectedFile);
                                if (selectedFile) {
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    setImagePreview(event.target.result);
                                  };
                                  reader.readAsDataURL(selectedFile);
                                } else {
                                  // Clear the preview if no file selected
                                  setImagePreview(null);
                                }
                              }}
                            />
                            {imagePreview ? (
                              <img
                                src={imagePreview}
                                alt="Selected"
                                style={{
                                  width: "50px",
                                  height: "40px",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#F3F3F3",
                                  borderRadius: "10px",
                                  padding: "10px 21px",
                                }}
                              >
                                <img
                                  src={uploadIcon}
                                  alt="Upload"
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                            )}
                          </Typography>
                        </Box>
                        {errors["photo"] && touched["photo"] && (
                          <Typography
                            component="p"
                            sx={{
                              color: "red",
                              fontSize: ".8rem",
                              textAlign: "left",
                              mr: "20px",
                            }}
                          >
                            {errors["photo"]}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      autoFocus
                      sx={{
                        borderRadius: "10px",
                        height: "52px",
                        width: "122px",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {loader ? (
                        <CircularProgress sx={{ color: "#fff" }} />
                      ) : params ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};
export default AddBikeModal;
