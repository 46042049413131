import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Card from "../../component/cards/iconCard/index";
import UserIcon from "../../assets/users.png";
import SpecialIcon from "../../assets/special.png";
import CycleIcon from "../../assets/cycle.png";
import EatIcon from "../../assets/eat.png";
import ScanIcon from "../../assets/scan.png";
import BgAddIcon from "../../assets/add-bg.png";
import AssignUser from "../../assets/assign-user.png";
import SideCard from "../../component/cards/sideCard";
import axios from "../../axios/index";
import UpcomingDeadline from "../../component/moment/upcommingDeadline";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [upcomingDeadline, setUpcomingDeadline] = useState([]);
  const [pendingBikeReq, setPendingBikeReq] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!upcomingDeadline.length) {
      axios
        .get("/upcoming-deadlines")
        .then((res) => {
          setUpcomingDeadline(res.data);
        })
        .catch((err) => {});
    }
    if (!pendingBikeReq.length) {
      axios
        .get("/get-pending-requests")
        .then((res) => {
          setPendingBikeReq(res.data);
        })
        .catch((err) => {});
    }
  }, []);

  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  useEffect(() => {
    axios
      .get(`/get-active-users?page=${page}&pageSize=${pageSize}`)
      .then((res) => {
        console.log(res.data);
        setActiveUsers((prevUsers) => [...prevUsers, ...res.data?.data]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]); // Add page and pageSize as dependencies

  const fileInputRef = useRef(null);

  // Function to trigger the file input click event
  const handleButtonClick = () => {
    // Use the current property of the ref to access the file input element
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile?.name.endsWith(".csv")) {
        const formData = new FormData();
        formData.append("posFile", selectedFile);
        setIsLoading(true);
        axios
          .post("/upload-pos", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success(res?.data?.message);
          })
          .catch((err) => {
            toast.error("Something went wrong!");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        toast.error("Only CSV Format is allow");
      }
    }
  };

  return (
    <Box sx={{ pt: "168px" }}>
      <Grid container>
        <Grid
          sx={{ background: "#fff", pb: "58px", pl: "40px", pr: "45px" }}
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: "16px",
              pb: "44px",
            }}
          >
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleButtonClick}
                sx={{
                  width: "150px",
                }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={24} />
                ) : (
                  "Upload POS File"
                )}
              </Button>
              <input
                type="file"
                hidden
                id="file-upload"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept=".xlsx, .csv"
              />
            </label>
            {/* <Typography
              component="p"
              sx={{
                color: "#081225",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "right",
              }}
            >
              30th July 2023
            </Typography> */}
          </Box>
          <Grid container spacing={3}>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card icon={ScanIcon} text="Scan Pass" />
            </Grid> */}
            {/*  <Grid item lg={4} md={12} sm={12} xs={12}>
              <Card
                route="/request-bikes"
                icon={CycleIcon}
                text="Bike Requests"
                pendingBikeReq={pendingBikeReq}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Card
                route="/assign-bikes"
                icon={CycleIcon}
                subIcon={AssignUser}
                WH={36}
                text="Assigned Bike’s"
                sx={{ height: "100%" }}
              />
            </Grid>*/}
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Card
                route="/add-bike"
                icon={CycleIcon}
                subIcon={BgAddIcon}
                WH={32}
                text="Add Bike’s"
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Card
                route="/update-letseat"
                icon={EatIcon}
                WH={32}
                text="Update Let’s Eat"
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Card
                route="/update-special"
                icon={SpecialIcon}
                text="Update Specials"
              />
            </Grid>
            <Grid item spacing={3} container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card
                  activeUsers={activeUsers}
                  icon={UserIcon}
                  text="In House Members"
                  users={true}
                  lastUserRef={listInnerRef}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{ mb: { lg: "0", md: "0", sm: "20px", xs: "20px" } }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ px: "21px", pt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  pb: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#081225",
                    fontSize: "14px",
                    fontWeight: "500",
                    mt: 1,
                  }}
                  variant="p"
                  component="p"
                >
                  RAYS ICON CHALLEGE
                </Typography>
                <Typography
                  sx={{
                    color: "#00B8B9",
                    fontSize: "12px",
                    fontWeight: "400",
                    mt: 1,
                    cursor: "pointer",
                  }}
                  variant="p"
                  component="p"
                  onClick={() => navigate("/rays-icon-challenge")}
                >
                  See All
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  padding: "20px 15px",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    color: "#081225",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  component="span"
                >
                  No Activity Found!
                </Typography>
                {/* {pendingBikeReq?.length ? (
                  pendingBikeReq.map((pending, key) => {
                    return (
                      <React.Fragment key={key}> */}
                {/* <SideCard
                  text="completed Jump 1"
                  title="Eloise Buck"
                  status="Today"
                  // index={key}
                /> */}
                {/* {key !== upcomingDeadline.length - 1 && ( */}
                {/* <Divider sx={{ my: "10px" }} /> */}
                {/* )} */}
                {/* <SideCard
                  text="completed Jump 1"
                  title="Sulaiman Escobar"
                  status="Yesterday"
                  // index={key}
                /> */}
                {/* {key !== upcomingDeadline.length - 1 && ( */}
                {/* <Divider sx={{ my: "10px" }} /> */}
                {/* )} */}
                {/* <SideCard
                  text="completed Jump 2"
                  title="Everett Mcconnell"
                  status="2 days agos"
                  // index={key}
                /> */}
                {/* {key !== upcomingDeadline.length - 1 && ( */}
                {/* <Divider sx={{ my: "10px" }} /> */}
                {/* )} */}
                {/* </React.Fragment>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      color: "#081225",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    component="span"
                  >
                    No Pending Request Found
                  </Typography>
                )} */}
              </Box>
            </Box>
          </Grid>

          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ px: "21px", pt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  pb: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#081225",
                    fontSize: "14px",
                    fontWeight: "500",
                    mt: 1,
                  }}
                  variant="p"
                  component="p"
                >
                  Upcoming Deadlines
                </Typography>
                <Typography
                  sx={{
                    color: "#00B8B9",
                    fontSize: "12px",
                    fontWeight: "400",
                    mt: 1,
                  }}
                  variant="p"
                  component="p"
                >
                  See All
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  padding: "20px 15px",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {upcomingDeadline?.length ? (
                  upcomingDeadline.map((deadline, key) => {
                    const { statusText, textColor } = UpcomingDeadline({
                      deadline: deadline?.deadline,
                    });
                    return (
                      <React.Fragment key={key}>
                        <SideCard
                          text={deadline?.bike[0]?.name}
                          title={deadline?.user[0]?.firstName}
                          status={statusText}
                          index={key}
                          textColor={textColor}
                        />
                        {key !== upcomingDeadline.length - 1 && (
                          <Divider sx={{ my: "10px" }} />
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      color: "#081225",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    component="span"
                  >
                    No Upcoming Deadline Found
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <Box sx={{ px: "21px", pt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  pb: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#081225",
                    fontSize: "14px",
                    fontWeight: "500",
                    mt: 1,
                  }}
                  variant="p"
                  component="p"
                >
                  Pending Requests
                </Typography>
                <Typography
                  sx={{
                    color: "#00B8B9",
                    fontSize: "12px",
                    fontWeight: "400",
                    mt: 1,
                    cursor: "pointer",
                  }}
                  variant="p"
                  component="p"
                  onClick={() => navigate("/request-bikes")}
                >
                  See All
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  padding: "20px 15px",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {pendingBikeReq?.length ? (
                  pendingBikeReq.slice(0, 3).map((pending, key) => {
                    return (
                      <React.Fragment key={key}>
                        <SideCard
                          text={pending?.bike[0]?.name}
                          title={pending?.user[0]?.firstName}
                          status="View"
                          index={key}
                        />
                        {key !== pendingBikeReq.length - 1 && (
                          <Divider sx={{ my: "10px" }} />
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      color: "#081225",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    component="span"
                  >
                    No Pending Request Found
                  </Typography>
                )}
              </Box>
            </Box> */}
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ px: "21px", pt: 1 }}>
                            <Box sx={{ display: 'flex', pb: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ color: '#081225', fontSize: '14px', fontWeight: '500', mt: 1 }} variant="p" component='p'>Recent Activities</Typography>
                                <Typography sx={{ color: '#00B8B9', fontSize: '12px', fontWeight: '400', mt: 1 }} variant="p" component='p'>See All</Typography>
                            </Box>
                            <SideCard text="Pass expires soon" title="John Micheal" status="2 Days Left" />
                        </Box>
                    </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
