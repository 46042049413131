import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';

const customTheme = createTheme({
  typography: {
    fontFamily: `Poppins, "Mulish", "Arial", sans-serif`,
  },
  palette: {
    // mode: "dark",
    background: {
      default: "#F5F5F5"
    },
    primary: {
      main: "#EA4D3D",
    },
    secondary: {
      main: "#2781EB",
    },
    danger: {
      main: "#E5343D",
    },
    custom: {
      main: "#fff",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
