import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import { addBikeValidation } from "../../validations";
import { Box } from "@mui/system";
import IconCheckBox from "./iconCheckBox";
import { Grid } from "@mui/material";
import SportIcon from "../../assets/sport.png";
import axios from "../../axios/index";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "500px",
    minWidth: "500px",
    borderRadius: "10px",
  },
}));

export default function AddIconModal({
  btnText,
  taskIcon,
  width,
  btnStyle,
  userId,
  fetchData,
  userIcons,
}) {
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: width && width }}>
      <Button
        variant="contained"
        sx={{
          ...btnStyle,
          width: width && width,
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        {btnText ? btnText : "Add New Bike"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Add Icons
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              icons: userIcons?.length
                ? userIcons?.map((itm) => itm?.task)
                : [],
            }}
            // validationSchema={addBikeValidation}
            onSubmit={({ icons }, { resetForm }) => {
              // resetForm();
              axios
                .post("/assign-icons", {
                  tasks: icons,
                  taskUser: userId,
                })
                .then((res) => {
                  setOpen(false);
                  fetchData();
                  toast.success("Successfully Assgined!");
                })
                .catch((err) => {
                  toast.error("Something wrong!");
                });
            }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      border: "1px solid #E0E0E0",
                      display: "flex",
                      mb: "15px",
                    }}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                      sx={{ p: "8px", border: "1px solid #E0E0E0" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                        }}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      sx={{ p: "8px", border: "1px solid #E0E0E0" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                        }}
                      >
                        Icon
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                      sx={{
                        p: "8px",
                        border: "1px solid #E0E0E0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        Eloise Buck
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      sx={{
                        p: "8px",
                        border: "1px solid #E0E0E0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "20px", height: "20px" }}>
                        <img
                          src={SportIcon}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mb="30px">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "rgba(0, 0, 0, 0.87)",
                          fontSize: "20px",
                          fontWeight: "400",
                          mb: "10px",
                        }}
                      >
                        Select Icons
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {taskIcon?.map((itm, ind) => {
                        return (
                          <Grid key={ind} item lg={4} md={4} sm={4} xs={4}>
                            <Field name="icons">
                              {({ field }) => (
                                <div>
                                  <IconCheckBox
                                    checked={field?.value?.includes(itm?._id)}
                                    value={itm?._id}
                                    data={itm}
                                    field={field}
                                    name={`icons[${ind}]`}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const updatedSizes = [...field?.value];
                                      if (e.target.checked) {
                                        updatedSizes.push(newValue);
                                      } else {
                                        const indexToRemove =
                                          updatedSizes.indexOf(newValue);
                                        if (indexToRemove !== -1) {
                                          updatedSizes.splice(indexToRemove, 1);
                                        }
                                      }
                                      field.onChange({
                                        target: {
                                          name: field.name,
                                          value: updatedSizes,
                                        },
                                      });
                                      if (!updatedSizes?.length) {
                                        setDisabled(true);
                                      } else {
                                        setDisabled(false);
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      autoFocus
                      disabled={disabled}
                      sx={{
                        borderRadius: "10px",
                        height: "52px",
                        width: "122px",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
