import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'; // Import React Quill
import './style.css'
const TextEditor = ({ value, onChange,placeholder,rows }) => {
  return (
    <div>
      {/* <TextField fullWidth multiline rows={rows} variant="outlined"> */}
        <ReactQuill
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          theme="snow"
          rows={rows?.length  ? rows: []}
        />
      {/* </TextField> */}
    </div>
  );
};

export default TextEditor;
