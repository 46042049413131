import * as Yup from "yup";

export const loginValidation = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 charaters")
    .required("Password is required"),
});
export const addBikeValidation = Yup.object({
  bikeName: Yup.string().required("Bike Name is required"),
  // sizes: Yup.array().required("At least one size is required"),
  // availability: Yup.boolean().required("At least one size is required"),
  photo: Yup.mixed().required("Photo is required"), // Add validation for the "photo" field
  inStock: Yup.mixed().required("Stock is required"), // Add validation for the "photo" field
});

export const returnTimeValidation = Yup.object({
  returnTime: Yup.string().required("Return date and time is required"),
});

export const membershipValidation = Yup.object({
  userId: Yup.string().required("User is required"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End Date is required"),
});

export const redeemValidation = Yup.object({
  userId: Yup.string().required("User is required"),
  amount: Yup.string().required("Amount is required"),
  points: Yup.string().required("Points is required"),
});
