// src/redux/reducers/exampleReducer.js
const initialState = {
    user: null,
    login: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            // Update state based on action
            return { ...state, user: action.payload, login: true };
        case 'LOGOUT':
            // Update state based on action
            return { ...state, user: action.payload, login: false };
        default:
            return state;
    }
};

export default userReducer;
