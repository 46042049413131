import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputField from "../../component/inputField/inputField";
import ClearIcon from "@mui/icons-material/Clear";
// import AddIcon from '@mui/icons-material/Add';
import AddIcon from "../../assets/add.png";
import axios from "../../axios/index";
import { toast } from "react-toastify";
import ScreenLoader from "../../component/screenLoader";
import FieldGroup from "../../component/fieldsGroup";
const LetsEat = () => {
  const [letsEat, setLetsEat] = useState([]);
  const [newEat, setNewEat] = useState("");
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!letsEat.length) {
      setLoader(true);
      axios
        .get("/get-lets-eat")
        .then((res) => setLetsEat(res?.data))
        .catch(() => {})
        .finally(() => setLoader(false));
    }
  }, []);
  const addNewEat = () => {
    if (!newEat) {
      toast.error("Please fill required field!");
    }
    setDisabled(true);
    axios
      .post("/add-new-eat", {
        name: newEat,
        link: "https://localhost.com",
      })
      .then((res) => {
        setLetsEat((prev) => [...prev, res?.data]);
        setNewEat("");
        if (res.status == 200) {
          toast.success("Successfully Added!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const deleteEat = (id) => {
    axios
      .delete("/delete-eat/" + id)
      .then((res) => {
        setLetsEat((prev) => prev.filter((eat) => eat?._id != id));
        if (res.status == 200) {
          toast.success("Successfully Deleted!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  if (loader) {
    return <ScreenLoader />;
  }

  return (
    <Box
      sx={{
        // height: "100vh",
        pt: "198px",
        pl: "40px",
        pr: "45px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Update Let’s Eat
      </Typography>
      <Grid container spacing={2}>
        <Grid item spacing={2} container lg={10} md={12} sm={12} xs={12}>
          {letsEat?.length
            ? letsEat?.map((eat, key) => {
                return (
                  <Grid item lg={4} md={6} sm={6} xs={12} key={key}>
                    <FieldGroup
                      ClearIcon={ClearIcon}
                      setNewEat={setNewEat}
                      newEat={eat?.name}
                      id={eat?._id}
                      deleteEat={() => deleteEat(eat?._id)}
                      setLetsEat={setLetsEat}
                    />
                  </Grid>
                );
              })
            : null}
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <FieldGroup
              // ClearIcon={ClearIcon}
              newEat={newEat}
              setNewEat={setNewEat}
              setLetsEat={setLetsEat}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={6}
          xs={12}
          // sx={{ display: "flex", alignItems: "center" }}
        >
          <Button
            variant="contained"
            disabled={disabled}
            sx={{
              // mt: 1,
              background: "#00B8B9",
              height: "28px",
              width: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              cursor: "pointer",
              minWidth: "28px",
              p: "0px",
              ":hover": {
                background: "#00a8a9",
              },
            }}
            onClick={() => addNewEat()}
          >
            <img src={AddIcon} />
            {/* </Box> */}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LetsEat;
