import * as React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { style } from "./style";

const InputField = ({
  label,
  placeholder,
  required,
  type,
  onChange,
  value,
  name,
  multiline,
  rows,
  Icon,
  sx,
  readonly,
  onClick,
  ml,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ ...style.inputLabel, ml: ml }}>
        {label}
      </Typography>
      <TextField
        sx={{ ...style.field, ...sx }}
        fullWidth
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        required={required}
        multiline={multiline}
        rows={rows}
        name={name}
        readoOnly={readonly}
        InputProps={
          Icon
            ? {
                endAdornment: (
                  <InputAdornment
                    onClick={onClick}
                    sx={{ cursor: "pointer" }}
                    position="end"
                  >
                    <Icon />
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </Box>
  );
};
export default InputField;
