import { Box, Button } from "@mui/material";
import SearchIcon from "../../assets/search.png";
import React from "react";
import "./index.css"

const SearchField = ({ value, onChange }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <input
        value={value}
        onChange={onChange}
        type="text"
        placeholder="Search"
        className="input"
      />
      <Button
        variant="contained"
        sx={{
          borderRadius: "0 20px 20px 0",
          minWidth: "50px",
          height: "49px",
          padding: "0",
          boxShadow:"none"
        }}
      >
        <img src={SearchIcon} alt="" />
      </Button>
    </Box>
  );
};

export default SearchField;
