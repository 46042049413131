import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import InputField from "../../component/inputField/inputField";
import { style } from "./style";
import { loginValidation } from "../../validations";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "../../axios/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  return (
    <Grid container sx={style.container}>
      <Grid item lg={4} md={5} sm={12} xs={12} sx={style.signinParentBox}>
        <Box sx={style.imgBox}>
          <img src={Logo} alt="Logo" width="100%" height="100%" />
        </Box>
        <Box sx={style.textBox}>
          <Typography component="h1" sx={style.headingSignin}>
            Sign in
          </Typography>
          <Typography component="p" sx={style.pSignin}>
            See your growth and get consulting support
          </Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginValidation}
          onSubmit={({ email, password }, { resetForm }) => {
            setLoader(true);
            axios
              .post("/login", {
                email,
                password,
              })
              .then((response) => {
                if (response?.data?.role == "admin") {
                  localStorage.setItem("jwtToken", response?.data?.token);
                  axios
                    .get("/profile")
                    .then((profile) => {
                      dispatch({ type: "LOGIN", payload: profile?.data });
                      navigate("/");
                      resetForm();
                    })
                    .then((err) => {
                      toast.error(err?.response?.data?.error);
                    });
                } else {
                  toast.error("Invalid Credentials");
                }
              })
              .catch((error) => {
                toast.error(error?.response?.data?.error);
              })
              .finally(() => {
                setLoader(false);
              });
          }}
        >
          {({ setFieldValue, errors, touched, values }) => {
            return (
              <Form style={{ width: "100%" }}>
                <Box sx={style.inputBox}>
                  <Box sx={{ mb: "20px" }}>
                    <InputField
                      label="Email"
                      placeholder="Enter your email"
                      mBottom
                      type="email"
                      name="email"
                      value={values["email"]}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value.toLowerCase());
                      }}
                    />
                    {errors["email"] && touched["email"] && (
                      <Typography component="p" sx={style.err}>
                        {errors["email"]}
                      </Typography>
                    )}
                  </Box>
                  <InputField
                    label="Password"
                    placeholder="Enter Password"
                    type="password"
                    name="password"
                    value={values["password"]}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    // required
                  />
                  {errors["password"] && touched["password"] && (
                    <Typography component="p" sx={style.err}>
                      {errors["password"]}
                    </Typography>
                  )}
                  <Box sx={style.forgotBox}>
                    <Typography sx={style.forgot}>Forgot password?</Typography>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={style.loginBtn}
                    disabled={loader ? true : false}
                  >
                    {loader ? (
                      <CircularProgress sx={{ color: "#fff" }} />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Signin;
