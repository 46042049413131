import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import InputField from "../inputField/inputField";
import { Form, Formik } from "formik";
import { returnTimeValidation } from "../../validations";
// import { Box } from "@mui/system";
// import { style } from "../../pages/signin/style";
import {
  CircularProgress,
  // FormControlLabel,
  // Grid,
  // Radio,
  TextField,
} from "@mui/material";
// import RadioGroupBtn from "../radio";
// import IconCheckboxes from "../checkBox";
// import CustomizedSwitches from "../toggelSwitch";
// import { style } from "../inputField/style";
import axios from "../../axios/index";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    width: "500px",
    borderRadius: "10px",
    padding: 20,
  },
}));

export default function ReturnTimeModal({ id, setRows }) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Return Time
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              returnTime: "",
            }}
            validationSchema={returnTimeValidation}
            onSubmit={({ returnTime }, { resetForm }) => {
              setLoader(true);
              axios
                .put("edit-return-time/" + id, {
                  returnTime,
                })
                .then((res) => {
                  toast.success(res?.data?.message);
                  setRows((prev) =>
                    prev.map((itm) => {
                      if (itm.id == id) {
                        return {
                          ...itm,
                          returnDate: new Date(returnTime).toLocaleTimeString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          ),
                          returnTime: new Date(returnTime).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          ),
                        };
                      } else {
                        return itm;
                      }
                    })
                  );

                  handleClose();
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.error);
                })
                .finally(() => {
                  setLoader(false);
                });

              resetForm();
            }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <TextField
                    type="datetime-local"
                    placeholder="Search"
                    sx={{
                      width: "100%",
                      ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                        padding: "12.5px 14px",
                      },
                      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                        borderColor: "#E0E0E0",
                        height: "54px",
                      },
                    }}
                    name="returnTime"
                    value={values["returnTime"]}
                    onChange={(e) => {
                      setFieldValue("returnTime", e.target.value);
                    }}
                  />
                  {errors["returnTime"] && touched["returnTime"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["returnTime"]}
                    </Typography>
                  )}
                  <DialogActions>
                    <Button
                      variant="contained"
                      type="submit"
                      autoFocus
                      sx={{
                        borderRadius: "10px",
                        height: "52px",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        fontSize: "16px",
                        mt: 1,
                      }}
                    >
                      {loader ? (
                        <CircularProgress sx={{ color: "#fff" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
