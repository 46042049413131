import "./App.css";
import Navbar from "../src/component/navbar";
import Signin from "./pages/signin/signin";
import Special from "./pages/specials/index";
import LetsEat from "./pages/letseat/index";
import AssignBikes from "./pages/assignBikes/index";
import RequestBikes from "./pages/requestBikes/index";
import IconChallenge from "./pages/iconChallenge/index";
import Membership from "./pages/memberships/index";
import Dashboard from "./pages/dashboard/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./component/privateRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import axios from "./axios/index";
import { Box, CircularProgress } from "@mui/material";
import ScreenLoader from "./component/screenLoader";
import AddBike from "./pages/addBike";
import RedeemPoints from "./pages/redeemPoints";
import Settings from "./pages/settings";
function App() {
  const isLoggedIn = useSelector((state) => state?.rootReducer?.userReducer);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    axios
      .get("/profile")
      .then((profile) => {
        dispatch({ type: "LOGIN", payload: profile?.data });
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT", payload: null });
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  if (isLoggedIn.user == null && loader) {
    return <ScreenLoader />;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Router>
        <Routes>
          {!isLoggedIn?.login ? (
            <Route exact path="/" element={<Signin />} />
          ) : (
            <>
              <Route
                exact
                path="/"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route path="/" element={<Navbar Component={Dashboard} />} />
              </Route>
              <Route
                path="/update-letseat"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/update-letseat"
                  element={<Navbar Component={LetsEat} />}
                />
              </Route>
              <Route
                path="/update-special"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/update-special"
                  element={<Navbar Component={Special} />}
                />
              </Route>
              <Route
                path="/assign-bikes"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/assign-bikes"
                  element={<Navbar Component={AssignBikes} />}
                />
              </Route>
              <Route
                path="/request-bikes"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/request-bikes"
                  element={<Navbar Component={RequestBikes} />}
                />
              </Route>
              <Route
                path="/rays-icon-challenge"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/rays-icon-challenge"
                  element={<Navbar Component={IconChallenge} />}
                />
              </Route>
              <Route
                path="/add-bike"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/add-bike"
                  element={<Navbar Component={AddBike} />}
                />
              </Route>

              <Route
                path="/memberships"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/memberships"
                  element={<Navbar Component={Membership} />}
                />
              </Route>
              <Route
                path="/redeem-points"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/redeem-points"
                  element={<Navbar Component={RedeemPoints} />}
                />
              </Route>
              <Route
                path="/settings"
                element={<PrivateRoute isLoggedIn={isLoggedIn?.login} />}
              >
                <Route
                  path="/settings"
                  element={<Navbar Component={Settings} />}
                />
              </Route>
            </>
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
