import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import AlertWarning from "../../assets/alert-warning-circle.png";
import axios from "../../axios/index";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "500px",
    minWidth: "500px",
    borderRadius: "10px",
  },
  // "& .MuiDialogContent-root":{
  //   padding:"30px"
  // }
}));

export default function RemoveBikeModal({
  btnText,
  bikeSizes,
  width,
  btnStyle,
  params,
  setRows,
  id
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    axios
      .delete("delete-bike/" + params?.id)
      .then(() => {
        toast.success("Successfully Delete");
        setRows((prev) => prev.filter((itm) => itm.id != params?.id));
        handleClose();
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <Box sx={{ width: width && width }}>
      <Button
        variant="contained"
        sx={{
          ...btnStyle,
          width: width && width,
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        {btnText ? btnText : "Add New Bike"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Remove Bike
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            // background:"rgb(0 0 0 / 4%)",
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            // color: "rgb(0 0 0 / 4%)",
            // "& :hover":{
            //     background:"#C1C1C1"
            // }
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent dividers> */}
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "82px",
                height: "82px",
                mb: "5px",
              }}
            >
              <img src={AlertWarning} alt="img" width="100%" height="100%" />
            </Box>
            <Box
              sx={{
                mb: "25px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Are you sure you want to remove
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                ({params?.bike})?
              </Typography>
            </Box>
          </Box>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              autoFocus
              onClick={handleClose}
              sx={{
                borderRadius: "10px",
                width: "122px",
                height: "52px",
                textTransform: "capitalize",
                fontWeight: "400",
                fontSize: "16px",
                mr: "30px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              onClick={handleRemove}
              sx={{
                borderRadius: "10px",
                width: "122px",
                height: "52px",
                textTransform: "capitalize",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Remove
            </Button>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
