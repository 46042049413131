import { Box, CircularProgress } from "@mui/material";

const ScreenLoader = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default ScreenLoader;
