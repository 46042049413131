// axiosInstance.js
import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3001/api", // Your API base URL
  baseURL: "https://admin.raysmtb.com/api", // Your API base URL
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    // Retrieve the JWT token from local storage
    const jwtToken = localStorage.getItem("jwtToken");

    // Add the token to the Authorization header if it exists
    if (jwtToken) {
      config.headers["Authorization"] = `Bearer ${jwtToken}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default instance;
