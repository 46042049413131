import * as React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { style } from "./style";
const Card = ({
  icon,
  subIcon,
  text,
  route,
  pendingBikeReq,
  WH,
  activeUsers,
  lastUserRef,
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={style.container}
      onClick={() => route && navigate(route)}
    >
      {pendingBikeReq?.length ? (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={style.notiCountDiv}>
          <Typography sx={style.notiCount}>{pendingBikeReq?.length}</Typography>
        </Grid>
      ) : null}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <img src={icon} />
        {subIcon ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <img src={subIcon} width={WH} height={WH} />
          </Box>
        ) : null}
      </Box>
      <Typography sx={style.cardText} variant="h2" component="h2">
        {text}
      </Typography>
      {activeUsers ? (
        <Box sx={style.IHMinnerBox} ref={lastUserRef}>
          <List>
            {activeUsers.map((data, index) => {
              return (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      sx={style.listData}
                      primary={data?.firstName}
                    />
                  </ListItem>
                  <Divider sx={style.divider} />
                </>
              );
            })}
          </List>
        </Box>
      ) : null}
    </Grid>
  );
};
export default Card;
