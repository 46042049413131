import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SearchSelect({
  options,
  onChange,
  value,
  name,
  params,
}) {
  return (
    <Autocomplete
      disablePortal
      onChange={(event, newValue) => {
        onChange(newValue); // Make sure newValue is the correct property of the selected option
      }}
      sx={{
        width: "100%",
        mb: 1,
        ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
          padding: "12.5px 14px",
        },
        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
          borderRadius: "20px",
          borderColor: "#E0E0E0",
          height: "54px",
        },
      }}
      disabled={params?.userId ? true : false}
      defaultValue={
        params?.userId
          ? options?.find((item) => item?.id == params?.userId)
          : { value: "", label: "Select a user" }
      }
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      options={options}
      renderInput={(params) => (
        <TextField {...params} value={value} name={name} />
      )}
    />
  );
}
