export const style = {
  inputLabel: {
    ml: "25px",
    fontFamily: "Quicksand",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "start",
  },
  field: {
    // mb: mBottom ? "20px" : 0,
    // mb: "20px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      paddingX: "10px",
    },
  },
};
