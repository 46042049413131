import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import GryDot from "../../assets/gryDot.png";
import OrangeDot from "../../assets/orangeDot.png";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { cleanFilterItem } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import { useState } from "react";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const IconCheckBox = ({ data, checked, value, field, name, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          border: "1px solid #EDEDED",
          background: "#F9F9F9",
          borderRadius: "18px",
          padding: "0 10px",
          mr: "5px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormControlLabel
          sx={{ mr: "0" }}
          control={
            <Checkbox
              icon={<img src={GryDot} />}
              checkedIcon={<img src={OrangeDot} />}
              checked={checked}
              value={value}
              field={field}
              name={name}
              onChange={onChange}
            />
          }
        />
        <img
          src={`https://admin.raysmtb.com/api/get-file/${data?.taskIcon?.filename}`}
          style={{ marginRight: "4px" }}
        />
        <Typography
          sx={{
            color: "#1F1F1F",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {data.taskName}
        </Typography>
      </Box>
    </Box>
  );
};
export default IconCheckBox;
