import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

import Typography from "@mui/material/Typography";
// import InputField from "../inputField/inputField";
import { Form, Formik } from "formik";
import { membershipValidation, returnTimeValidation } from "../../validations";
// import { Box } from "@mui/system";
// import { style } from "../../pages/signin/style";
import {
  CircularProgress,
  // FormControlLabel,
  // Grid,
  // Radio,
  TextField,
} from "@mui/material";
// import RadioGroupBtn from "../radio";
// import IconCheckboxes from "../checkBox";
// import CustomizedSwitches from "../toggelSwitch";
// import { style } from "../inputField/style";
import axios from "../../axios/index";
import { toast } from "react-toastify";
import SearchSelect from "../select/index";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    width: "500px",
    borderRadius: "10px",
    padding: 20,
  },
}));

export default function MemberShipModal({
  setRows,
  fetchData,
  params,
  id,
  users,
}) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const convertDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Add one day
    dateObject.setDate(dateObject.getDate() + 1);
    const formattedDate = dateObject.toISOString().slice(0, 16);
    return formattedDate;
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        {params?.id ? <EditIcon /> : "Add Membership"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Membership
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              userId: params?.userId ? params?.userId : "",
              startDate: params?.startDate
                ? convertDateString(params?.startDate)
                : "",
              endDate: params?.endDate
                ? convertDateString(params?.endDate)
                : "",
            }}
            validationSchema={membershipValidation}
            onSubmit={({ userId, startDate, endDate }, { resetForm }) => {
              setLoader(true);
              const requestMethod = params ? "put" : "post";
              const request = params
                ? "/edit-user-membership/" + params?.id
                : "/add-user-membership";
              axios[requestMethod](request, {
                userId,
                startDate,
                endDate,
              })
                .then((res) => {
                  toast.success(
                    `Membership successfully ${params ? "edited" : "added"}`
                  );
                  handleClose();
                  if (params) {
                    setRows((prev) =>
                      prev.map((itm) => {
                        if (itm.id == id) {
                          return {
                            ...itm,
                            startDate: new Date(
                              res?.data?.startDate
                            ).toDateString(),
                            endDate: new Date(
                              res?.data?.endDate
                            ).toDateString(),
                          };
                        } else {
                          return itm;
                        }
                      })
                    );
                  } else {
                    fetchData();
                  }
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.error);
                })
                .finally(() => {
                  setLoader(false);
                });

              resetForm();
            }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <label>Users</label>
                  <SearchSelect
                    options={users}
                    onChange={(selectedValue) => {
                      setFieldValue("userId", selectedValue?.id); // Make sure "userId" matches the field name in Formik
                    }}
                    value={values["userId"]}
                    name="userId"
                    params={params}
                  />
                  {errors["userId"] && touched["userId"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["userId"]}
                    </Typography>
                  )}
                  <label>Start Date</label>
                  <TextField
                    type="datetime-local"
                    placeholder="Search"
                    sx={{
                      width: "100%",
                      mb: 1,
                      ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                        padding: "12.5px 14px",
                      },
                      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                        borderColor: "#E0E0E0",
                        height: "54px",
                      },
                    }}
                    name="startDate"
                    value={values["startDate"]}
                    onChange={(e) => {
                      setFieldValue("startDate", e.target.value);
                    }}
                  />
                  {errors["startDate"] && touched["startDate"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["startDate"]}
                    </Typography>
                  )}
                  <label>End Date</label>
                  <TextField
                    type="datetime-local"
                    placeholder="Search"
                    sx={{
                      width: "100%",
                      ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                        padding: "12.5px 14px",
                      },
                      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                        borderColor: "#E0E0E0",
                        height: "54px",
                      },
                      mb: 1,
                    }}
                    name="endDate"
                    value={values["endDate"]}
                    onChange={(e) => {
                      setFieldValue("endDate", e.target.value);
                    }}
                    inputProps={{ min: new Date().toISOString().slice(0, 16) }}
                  />
                  {errors["endDate"] && touched["endDate"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["endDate"]}
                    </Typography>
                  )}
                  <DialogActions>
                    <Button
                      variant="contained"
                      type="submit"
                      autoFocus
                      sx={{
                        borderRadius: "10px",
                        height: "52px",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        fontSize: "16px",
                        mt: 1,
                      }}
                    >
                      {loader ? (
                        <CircularProgress sx={{ color: "#fff" }} />
                      ) : params ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
