import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
// import AlertWarning from "../../assets/alert-warning-circle.png";
import axios from "../../axios/index";
import { toast } from "react-toastify";
import CheckIcon from "../../assets/check.png";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "500px",
    minWidth: "500px",
    borderRadius: "10px",
  },
  // "& .MuiDialogContent-root":{
  //   padding:"30px"
  // }
}));

export default function Accepted({
  // btnText,
  // bikeSizes,
  width,
  btnStyle,
  // params,
  setRows,
  // textColor,
  id,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //   axios
  //     .put("/update-request-status/" + params.row.id, {
  //       status,
  //     })
  //     .then((res) => {
  //       toast.success(`The Request has been ${status}`);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err?.message);
  //     })
  //     .finally(() => {});

  const handleAccepted = () => {
    axios
      .put("/update-request-status/" + id, { status: "accepted" })
      .then(() => {
        toast.success("The Request has been accepted!");
        // setRows((prev) => prev.filter((itm) => itm.id != params?.id));
        setRows((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return { ...item, status: "accepted" };
            } else {
              return item;
            }
          })
        );

        handleClose();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {});
  };

  return (
    <Box sx={{ width: width && width }}>
      <Button
        variant="contained"
        sx={{
          ...btnStyle,
          //   width: width && width,
          //   height: "45px",
          //   borderRadius: "10px",
          //   textTransform: "capitalize",
          //   color: textColor && textColor
          height: "32px",
          minWidth: "32px",
          background: "#00B232",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer",
          padding: "0",
          "&:hover": {
            background: "#067224",
          },
        }}
        onClick={handleClickOpen}
      >
        <img src={CheckIcon} />
        {/* {btnText ? btnText : "Add New Bike"} */}
      </Button>
      {/* <Box
                sx={{
                  height: "32px",
                  width: "32px",
                  background: "#EA4D3D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => swalAlert(params?.row?.id, "declined")}
              >
                <img src={CrossIcon} />
              </Box> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Accepted
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            // background:"rgb(0 0 0 / 4%)",
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            // color: "rgb(0 0 0 / 4%)",
            // "& :hover":{
            //     background:"#C1C1C1"
            // }
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent dividers> */}
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "82px",
                height: "82px",
                mb: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleRoundedIcon
                sx={{ width: "100%", height: "100%", color: "#00B232" }}
              />
            </Box>
            <Box
              sx={{
                mb: "25px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Are you sure you want to Accept?
              </Typography>
            </Box>
          </Box>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              autoFocus
              onClick={handleClose}
              sx={{
                borderRadius: "10px",
                width: "122px",
                height: "52px",
                textTransform: "capitalize",
                fontWeight: "400",
                fontSize: "16px",
                mr: "30px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              onClick={handleAccepted}
              sx={{
                borderRadius: "10px",
                width: "122px",
                height: "52px",
                textTransform: "capitalize",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Accept
            </Button>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
