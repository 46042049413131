import * as React from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import axios from "../../axios/index";
import debounce from "lodash/debounce";
import ScreenLoader from "../../component/screenLoader";
import SearchField from "../../component/searchField";
import Declined from "../../component/modal/declined";
import Accepted from "../../component/modal/accepted";
import RedeemPointsModal from "../../component/modal/redeemPointsModal";
import MemberShipModal from "../../component/modal/memberShipModal";
import { toast } from "react-toastify";
export default function Memberships() {
  const [users, setUsers] = React.useState([]);

  const columns = [
    { field: "id", headerName: "NO", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1 },
    // { field: "userId", headerName: "userId", flex: 1, hide: true },
    { field: "endDate", headerName: "End Date", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // gap: 1,
          }}
        >
          {/* <RedeemPointsModal id={params.row.id} params={params.row} setRows={setRows} /> */}
          <MemberShipModal
            id={params.row.id}
            params={params.row}
            setRows={setRows}
            users={users}
          />
        </Box>
      ),
    },
  ];

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(""); // State for search query
  const [loader, setLoader] = React.useState(true);

  var fetchData = async (searchText = "") => {
    axios
      .get(
        `/get-membership?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchText}`
      )
      .then((res) => {
        // Transform the received data into the desired format
        const transformedData = res?.data?.data?.map((item) => ({
          id: item._id,
          name: `${item?.user[0]?.firstName} ${item?.user[0]?.lastName}`,
          email: `${item?.user[0]?.email}`,
          userId: item?.user[0]?._id,
          startDate: `${new Date(item?.startDate).toDateString()}`,
          endDate: `${new Date(item?.endDate).toDateString()}`,
        }));

        setRows(transformedData);
        setTotalRows(res?.data?.totalRecords);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage?.page);
  };

  // Define a debounce delay (e.g., 300 milliseconds)
  const debounceDelay = 300;

  // Create a debounced search function
  const debouncedSearch = debounce((searchText = "") => {
    fetchData(searchText);
  }, debounceDelay);

  // Handle search input change
  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setPage(0); // Reset to the first page when searching
    setSearchQuery(searchText);
    if (!searchText || searchText == "") {
      debouncedSearch();
    } else if (searchText.length >= 3) {
      debouncedSearch(searchText);
    }
  };

  // Initial data fetch
  React.useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  React.useEffect(() => {
    if (!users?.length) {
      axios
        .get(`/get-all-users?allUser=true`)
        .then((res) => {
          setUsers(
            res?.data?.data.map((itm) => {
              return {
                id: itm?._id,
                label: `${itm.firstName} ${itm.lastName}`,
              };
            })
          );
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    }
  }, [users]);

  if (loader) {
    return <ScreenLoader />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "198px",
        pb: "150px",
        pl: "24px",
        pr: "60px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Memberships
      </Typography>
      <div style={{ width: "100%", height: 400 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "30px",
          }}
        >
          <Box
            sx={{
              mr: "20px",
            }}
          >
            <SearchField value={searchQuery} onChange={handleSearchChange} />
          </Box>
          <MemberShipModal
            users={users}
            setRows={setRows}
            fetchData={fetchData}
          />
        </Box>

        <DataGrid
          sx={{
            borderRadius: "20px",
            ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#2F2F2F",
            },
            ".css-19p8xgs-MuiDataGrid-root .MuiDataGrid-cellContent": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#1F1F1F",
            },
            ".MuiDataGrid-withBorderColor": {
              pl: "10px",
            },
            ".MuiDataGrid-cell": {
              pl: "20px !important",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: pageSize },
            },
          }}
          rows={rows?.length ? rows : []}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={[10]}
        />
      </div>
    </Box>
  );
}
