export const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    // py: "50px",
    // px: "30px",
    // border: "1px solid red",
  },
  signinParentBox: {
    // border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  imgBox: {
    width: "149px",
    height: "193px",
    mb: "20px",
    // border: "1px solid red",
  },
  headingSignin: {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: "600",
  },
  pSignin: {
    fontFamily: "Quicksand",
    fontSize: "20px",
    fontWeight: "400",
    mb: "25px",
  },
  inputBox: { width: "100%", textAlign: "center" },
  forgotBox: { textAlign: "end" },
  forgot: {
    // font-family: Quicksand;
    fontSize: "20px",
    fontWeight: "400",
    // textAlign: "left",
    color: "#F15A3B",
    cursor: "pointer",
    margin: "10px 20px 25px 0px",
  },
  loginBtn: {
    background: "#F15A3B",
    width: "80%",
    p: "10px 0 10px 0",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  err: {
    color: "red",
    fontSize: ".8rem",
    textAlign: "left",
    mr: "20px",
    // mt: 1,
    // ml: 1,
  },
  textBox: {
    // border: "1px solid black",
    width: "100%",
    textAlign: "center",
  },
};
