import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import InputField from "../../component/inputField/inputField";
import axios from "../../axios/index";
import { toast } from "react-toastify";

function Settings() {
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(password);

    axios
      .post(`/update-screen-password`, {
        password: password,
      })
      .then((res) => {
        toast.success("Password successfully updated!");
        setPassword("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box
      sx={{
        height: "100vh",
        pt: "198px",
        pb: "150px",
        pl: "24px",
        pr: "60px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Settings
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid md={4} lg={4} sm={12} xs={12}>
            <label>Update Screen Password</label>
            <InputField
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              placeholder="Password"
            />
            <Box textAlign="right">
              <Button type="submit" variant="contained" sx={{ mt: 1 }}>
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default Settings;
