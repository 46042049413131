import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// import EditIcon from "@mui/icons-material/Edit";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Modal from "../modal";
import EditIconC from "../../assets/editIcon.png";
import { Box } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  // ".css-xbbcdn-MuiButtonBase-root-MuiMenuItem-root":{
  //   padding:"0"
  // },
  "& .MuiPaper-root": {
    borderRadius: "10px",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomizedMenus = ({
  options,
  id,
  handleMakeRider,
  handleRemoveMakeRider,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}

        sx={{
          height: "30px",
          width: "30px",
          minWidth: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          background: "transparent",
          padding: "0px",
          boxShadow: "none",
          "&:hover": {
            background: "rgb(255 223 220)",
          },
          // borderRadius: "50%",
        }}
      >
        <img src={EditIconC} width="100%" height="100%" />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options?.map((itm, ind) => {
          return (
            <Box>
              <MenuItem
                onClick={() => {
                  if (itm?.MRTW) {
                    handleMakeRider(id);
                  } else if (itm?.RRTW) {
                    handleRemoveMakeRider(id);
                  }
                }}
                sx={{
                  p: itm?.cPadding && itm?.cPadding,
                  color: itm?.textColor && itm.textColor,
                }}
              >
                {itm.label}
                {/* <Modal btnText="Add Icon"/> */}
              </MenuItem>
              {ind === options.length - 1 ? null : <Divider sx={{ my: 0.5 }} />}
            </Box>
          );
        })}
      </StyledMenu>
    </div>
  );
};
export default CustomizedMenus;
