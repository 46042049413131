import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import UsersIcons from "@mui/icons-material/People";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import RedeemIcon from "@mui/icons-material/Redeem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SideBarImg from "../../assets/bg-sidebar.png";
import logo from "../../assets/logo.png";
import dashboardIcon from "../../assets/dashboard.png";
import settingIcon from "../../assets/setting.png";
import SearchIcon from "../../assets/searchb.png";
import BellIcon from "../../assets/bell.png";
import UserIcon from "../../assets/user.png";
import DownIcon from "../../assets/down.png";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { style } from "./style";
// import SearchIcon from '@mui/icons-material/Search';

const drawerWidth = 281;

function ResponsiveDrawer(props) {
  const { window, Component } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const state = useSelector((state) => state?.rootReducer?.userReducer);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT", payload: null });
    navigate("/");
  };

  const drawer = (
    <Box sx={style.mainSideBar}>
      <Box>
        <Box
          sx={{ ...style.logoBox, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img src={logo} width={92} height={119} alt="" />
        </Box>
        <Divider />
        <List sx={style.list}>
          {[
            { name: "Dashboard", route: "/", Icon: dashboardIcon },
            {
              name: "Memberships",
              route: "/memberships",
              Icon: CardMembershipIcon,
            },
            {
              name: "Redeem",
              route: "/redeem-points",
              Icon: RedeemIcon,
            },
          ].map(({ route, name, Icon }, index) => (
            <ListItem
              onClick={() => navigate(route)}
              sx={{
                ...style.listItem,
                marginTop: index == 0 ? "80px" : "20px",
              }}
              key={index}
              disablePadding
            >
              <ListItemButton sx={style.listItemBtn}>
                <ListItemIcon sx={style.listItemIcon}>
                  {index == 0 ? (
                    <img src={Icon} width={25} height={25} alt="" />
                  ) : (
                    <Icon />
                  )}
                </ListItemIcon>
                <ListItemText sx={style.listItemText} primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider />
      <List sx={style.list}>
        {[
          {
            name: "Settings",
            route: "/settings",
            Icon: settingIcon,
          },
        ].map((text, index) => (
          <ListItem
            onClick={() => navigate(text.route)}
            sx={style.listItemSetting}
            key={text.name}
            disablePadding
          >
            <ListItemButton sx={style.listItemBtn}>
              <ListItemIcon sx={style.listItemIcon}>
                <img src={text.Icon} width={25} height={25} alt="" />
              </ListItemIcon>
              <ListItemText sx={style.listItemText} primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={style.main}>
      <CssBaseline />
      <AppBar position="fixed" sx={style.appBar}>
        <Toolbar sx={style.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={style.iconBtn}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ ...style.toolBarTextUN, textTransform: "capitalize" }}
            noWrap
            component="div"
          >
            Hello, {state?.user?.firstName}
          </Typography>
          <Box sx={style.navItemBox}>
            {/* <TextField
              placeholder="Search"
              sx={style.serchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={style.notiBox}>
              <Box sx={style.notiCountBox}></Box>
              <img src={BellIcon} width={18} height={21} alt="" />
            </Box> */}
            <Box sx={style.userIconBox}>
              <img src={UserIcon} alt="" />
              <Box sx={style.userDetailsBox}>
                <Typography
                  sx={{ ...style.userName, textTransform: "capitalize" }}
                  component="p"
                >
                  {state?.user?.firstName}
                </Typography>
                <Typography sx={style.userRole} component="span">
                  Admin
                </Typography>
              </Box>
              <img
                style={style.dropDownIcon}
                onClick={handleClick}
                src={DownIcon}
                alt=""
              />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    paddingRight: "64px",
                    color: "#EA4D3D",
                    borderRadius: "20px",
                    boxShadow: "none",
                    top: "112px !important",
                    // left: '701px !important',
                    marginLeft: "10px",
                    background: "#F9F9F9",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "& .css-1eaqvnp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                      {
                        paddingRight: "59px",
                        color: "#EA4D3D",
                        borderRadius: "20px",
                        boxShadow: "none",
                      },
                    // "&:before": {
                    //   content: '""',
                    //   display: "block",
                    //   position: "absolute",
                    //   top: 0,
                    //   right: 14,
                    //   width: 10,
                    //   height: 10,
                    //   bgcolor: "background.paper",
                    //   transform: "translateY(-50%) rotate(45deg)",
                    //   zIndex: 0,
                    // },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F9F9F9",
                    },
                    backgroundColor: "#F9F9F9",
                    ".MuiListItemIcon-root": {
                      background: "rgba(234, 77, 61, 0.1)",
                      width: "32px",
                      height: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      color: "#EA4D3D",
                      marginRight: "5px",
                    },
                  }}
                  onClick={handleLogout}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Component />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
