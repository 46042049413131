import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  borderRadius: "50px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#F3F3F3" : "#F3F3F3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },

  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#F3F3F3" : "#F3F3F3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&:after, &:before": {
      color: "#616161",
      fontSize: "14px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'On'",
      left: "8px"
    },
    "&:before": {
      content: "'Off'",
      right: "7px"
    }
  }
}));

const CustomizedSwitches = ({ label, field }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: "30px" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
        {label}
      </Typography>
      <FormGroup>
        <FormControlLabel
          sx={{
            margin: "0px",
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 27,
              height: 27,
              backgroundColor: field.value ?  "#00B232" : "#EA4D3D",
            },
          }}
          control={
            <IOSSwitch
              checked={field.value} // Set the switch value based on Formik field's value
              onChange={field.onChange} // Use Formik's onChange handler
              sx={{ m: 1 }}
              name="availability"
              value={field.value}
              label="On" // Label for the "On" state
              labelPlacement="start" // Position of the label relative to the switch (e.g., "start" for before the switch)
            />
          }
        />
      </FormGroup>
    </Box>
  );
};
export default CustomizedSwitches;
