import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import * as Yup from "yup";

import Typography from "@mui/material/Typography";
// import InputField from "../inputField/inputField";
import { Form, Formik } from "formik";
import {
  membershipValidation,
  redeemValidation,
  returnTimeValidation,
} from "../../validations";
// import { Box } from "@mui/system";
// import { style } from "../../pages/signin/style";
import {
  CircularProgress,
  // FormControlLabel,
  // Grid,
  // Radio,
  TextField,
} from "@mui/material";
// import RadioGroupBtn from "../radio";
// import IconCheckboxes from "../checkBox";
// import CustomizedSwitches from "../toggelSwitch";
// import { style } from "../inputField/style";
import axios from "../../axios/index";
import { toast } from "react-toastify";
import SearchSelect from "../select/index";
import { Box } from "@mui/system";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    width: "500px",
    borderRadius: "10px",
    padding: 20,
  },
}));

export default function RedeemPointsModal({
  setRows,
  fetchData,
  params,
  id,
  users,
  tableRows,
}) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [userPoints, setUserPoints] = React.useState(0);
  const [userPointsDef, setUserPointsDef] = React.useState(0);
  const [userTotalPoints, setUserTotalPoints] = React.useState(0);
  const handleClickOpen = async () => {
    setOpen(true);
    if (params?.id) {
      try {
        const redeemPoins = await axios.get(
          "/get-user-redeem-points/" + params?.userId
        );
        setUserPoints(redeemPoins?.data?.points);
        setUserPointsDef(redeemPoins?.data?.points);
        setUserTotalPoints(redeemPoins?.data?.totalPoints);
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        {params?.id ? <EditIcon /> : "Add Redeem Points"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {params?.id ? "Edit Redeem Points" : "Add Redeem Points"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              userId: params?.userId ? params?.userId : "",
              amount: params?.amount ? params?.amount : "",
              points: "",
            }}
            validationSchema={
              // ...redeemValidation,
              Yup.object({
                userId: Yup.string().required("User is required"),
                amount: Yup.number().required("Amount is required"),
                points: params?.id
                  ? Yup.number()
                      .required("Points are required")
                      .test(
                        "maxPoints",
                        `The user has only ${userPointsDef.toFixed(
                          2
                        )} points remaining!`,
                        (value) => {
                          const numericValue = parseInt(value, 10);
                          return numericValue <= userPointsDef;
                        }
                      )
                  : Yup.number().required("Points is required"),
              })
            }
            onSubmit={({ userId, amount, points }, { resetForm }) => {
              setLoader(true);
              const requestMethod = params ? "put" : "post";
              const request = params
                ? "/edit-redeem-points"
                : "/add-redeem-points";
              axios[requestMethod](request, {
                userId,
                amount,
                points,
              })
                .then((res) => {
                  toast.success(
                    `Redeem points successfully ${params ? "edited" : "added"}`
                  );
                  handleClose();
                  if (params) {
                    // setRows((prev) =>
                    //   prev.map((itm) => {
                    //     if (itm.id == id) {
                    //       return {
                    //         ...itm,
                    //         amount: res?.data?.amount,
                    //         points: res?.data?.points,
                    //       };
                    //     } else {
                    //       return itm;
                    //     }
                    //   })
                    // );
                  } else {
                    fetchData();
                  }
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.error);
                })
                .finally(() => {
                  setLoader(false);
                });

              resetForm();
            }}
          >
            {({ setFieldValue, errors, touched, values }) => {
              return (
                <Form style={{ width: "100%" }}>
                  <label>Users</label>
                  <SearchSelect
                    options={users}
                    onChange={(selectedValue) => {
                      setFieldValue("userId", selectedValue?.id); // Make sure "userId" matches the field name in Formik
                    }}
                    value={values["userId"]}
                    name="userId"
                    params={params}
                  />
                  {errors["userId"] && touched["userId"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["userId"]}
                    </Typography>
                  )}
                  {!params?.userId ? (
                    <>
                      <label>Amount</label>
                      <TextField
                        type="Number"
                        placeholder="Enter amount"
                        sx={{
                          width: "100%",
                          mb: 1,
                          ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              padding: "12.5px 14px",
                            },
                          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            borderRadius: "20px",
                            borderColor: "#E0E0E0",
                            height: "54px",
                          },
                        }}
                        name="amount"
                        value={values["amount"]}
                        onChange={(e) => {
                          let val = e.target.value;
                          setFieldValue("amount", e.target.value);
                          let pointsPer = (val / 100) * 10;
                          if (!val) {
                            setFieldValue("points", "");
                          } else {
                            setFieldValue("points", pointsPer);
                          }
                        }}
                      />
                      {errors["amount"] && touched["amount"] && (
                        <Typography
                          component="p"
                          sx={{
                            color: "red",
                            fontSize: ".8rem",
                            textAlign: "left",
                            mr: "20px",
                          }}
                        >
                          {errors["amount"]}
                        </Typography>
                      )}
                    </>
                  ) : null}

                  <label>
                    {params?.userId
                      ? "Calculate Redeem Points"
                      : "Redeem Points"}
                  </label>
                  <TextField
                    type="number"
                    inputProps={{ readOnly: params?.id ? false : true }}
                    placeholder="Redeem Points"
                    sx={{
                      width: "100%",
                      ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                        padding: "12.5px 14px",
                      },
                      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                        borderColor: "#E0E0E0",
                        height: "54px",
                      },
                      mb: 1,
                    }}
                    name="points"
                    value={values["points"]}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) {
                        setUserPoints(+userPointsDef - +val);
                      } else {
                        setUserPoints(userPointsDef);
                      }
                      setFieldValue("points", val);
                    }}
                  />
                  {errors["points"] && touched["points"] && (
                    <Typography
                      component="p"
                      sx={{
                        color: "red",
                        fontSize: ".8rem",
                        textAlign: "left",
                        mr: "20px",
                      }}
                    >
                      {errors["points"]}
                    </Typography>
                  )}
                  {params?.userId ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ textAlign: "right", fontSize: "13px" }}>
                        Total Points: {userTotalPoints.toFixed(2)}
                      </Typography>
                      <Typography sx={{ textAlign: "right", fontSize: "13px" }}>
                        Remaining Points: {userPoints.toFixed(2)}
                      </Typography>
                    </Box>
                  ) : null}
                  <DialogActions>
                    <Button
                      variant="contained"
                      type="submit"
                      autoFocus
                      sx={{
                        borderRadius: "10px",
                        height: "52px",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        fontSize: "16px",
                        mt: 1,
                      }}
                    >
                      {loader ? (
                        <CircularProgress sx={{ color: "#fff" }} />
                      ) : params ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
