import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputField from "../inputField/inputField";
import { Field, Form, Formik } from "formik";
import { addBikeValidation } from "../../validations";
import { Box } from "@mui/system";
import { style } from "../../pages/signin/style";
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
} from "@mui/material";
// import RadioGroupBtn from "../radio";
import IconCheckboxes from "../checkBox";
import CustomizedSwitches from "../toggelSwitch";
// import { style } from "../inputField/style";
import uploadIcon from "../../assets/upload.png";
import { useState } from "react";
import axios from "../../axios/index";
import { toast } from "react-toastify";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    padding: "0px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    maxWidth: "500px",
    minWidth: "500px",
    borderRadius: "10px",
  },
  // "& .MuiDialogContent-root":{
  //   padding:"30px"
  // }
}));

export default function EditEatModal({ id, newEat, setLetsEat }) {
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [mainCat, setMainCat] = React.useState(newEat);
  const handleClickOpen = () => {
    setOpen(true);
    axios
      .get("/get-eat-category/" + id)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch(() => {});
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (key, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[key][field] = value;
    setCategories(updatedCategories);
  };

  function updateCat(catId) {
    const findCat = categories?.find((itm) => itm?._id == catId);
    if (findCat) {
      axios
        .put("/update-eat-category/" + catId, {
          companyName: findCat.companyName,
          address: findCat.address,
          menu: findCat.menu,
          phone: findCat.phone,
          category: findCat.category,
          link: findCat.link,
        })
        .then((res) => {
          toast.success("Updated successfully");
          setOpen(false);
        })
        .catch(() => {
          toast.error("Something wrong");
        });
    }
  }

  const updateName = () => {
    if (!mainCat) {
      toast.error("Name is requried!");
      return;
    }
    axios
      .put("/edit-eat/" + id, {
        name: mainCat,
      })
      .then((res) => {
        setLetsEat((prev) =>
          prev.map((itm) => {
            if (itm?._id == id) {
              return {
                ...itm,
                name: mainCat,
              };
            } else {
              return itm;
            }
          })
        );
        toast.success("Updated successfully");
        setOpen(false);
      })
      .catch(() => {
        toast.error("Something wrong");
      });
  };
  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          //   ...btnStyle,
          // width: 550,
          height: "45px",
          borderRadius: "10px",
          textTransform: "capitalize",
        }}
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            width: 550,
          },
          "& .css-uhb5lp": {
            width: 550,
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
          }}
          id="customized-dialog-title"
        >
          Edit Eat
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            // background:"rgb(0 0 0 / 4%)",
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            // color: "rgb(0 0 0 / 4%)",
            // "& :hover":{
            //     background:"#C1C1C1"
            // }
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent dividers> */}
        <DialogContent sx={{ mb: 2 }}>
          <InputField
            sx={{
              mt: "10px",
              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
              },
              ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                padding: "8px 2px",
              },
            }}
            // placeholder=""
            onChange={(e) => setMainCat(e.target.value)}
            value={mainCat}
          />
          <Button
            sx={{
              borderRadius: "10px",
              fontSize: "16px",
              textTransform: "capitalize",
              fontWeight: 400,
              width: "92px",
              marginTop: 1,
              mb: 1,
            }}
            variant="contained"
            onClick={() => updateName(id)}
            // disabled={loader}
          >
            Update
          </Button>
          <Divider />

          {categories.map((itm, key) => (
            <div key={key}>
              <InputField
                sx={{
                  mt: "10px",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "8px 2px",
                  },
                }}
                placeholder="Company Name"
                value={itm.companyName}
                onChange={(e) =>
                  handleInputChange(key, "companyName", e.target.value)
                }
              />
              <InputField
                sx={{
                  mt: "10px",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "8px 2px",
                  },
                }}
                placeholder="Address"
                value={itm.address}
                onChange={(e) =>
                  handleInputChange(key, "address", e.target.value)
                }
              />
              <InputField
                sx={{
                  mt: "10px",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "8px 2px",
                  },
                }}
                placeholder="Menu"
                value={itm.menu}
                onChange={(e) => handleInputChange(key, "menu", e.target.value)}
              />
              <InputField
                sx={{
                  mt: "10px",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "8px 2px",
                  },
                }}
                placeholder="Phone"
                value={itm.phone}
                onChange={(e) =>
                  handleInputChange(key, "phone", e.target.value)
                }
              />
              <InputField
                sx={{
                  mt: "10px",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "8px 2px",
                  },
                }}
                placeholder="Link"
                value={itm.link}
                onChange={(e) => handleInputChange(key, "link", e.target.value)}
              />
              <Button
                sx={{
                  borderRadius: "10px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  fontWeight: 400,
                  width: "92px",
                  marginTop: 1,
                  mb: 1,
                }}
                variant="contained"
                // Add your onClick handler to update data in the state
                onClick={() => updateCat(itm?._id)}
              >
                Update
              </Button>
              <Divider />
            </div>
          ))}
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
