export const style = {
  container: {
    background: "#F8F8F8",
    padding: "15px 0 25px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "30px",
    position: "relative",
    cursor: "pointer",
    minHeight: "160px",
  },
  notiCountDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    pr: "15px",
  },
  notiCount: {
    background: "#EA4D3D",
    width: "20px",
    height: "20px",
    borderRadius: "50px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
  },
  cardText: {
    color: "#353535",
    fontSize: "22px",
    fontWeight: "500",
    mt: "20px",
  },
  IHMinnerBox: {
    height: "191px",
    overflow: "auto",
    background: "#fff",
    width: "-webkit-fill-available",
    mt: "10px",
    mx: "21px",
    borderRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#00B8B9",
    },
  },
  listData: {
    ".css-1c785r0-MuiTypography-root": {
      cursor: "pointer",
      color: "#081225",
      fontSize: "12px",
    },
  },
  divider: { width: "90%", margin: "0 auto" },
};
