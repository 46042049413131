import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
// import Jump1Icone from "../../assets/jump1.png";
// import Jump2Icone from "../../assets/jump2.png";
// import Jump3Icone from "../../assets/jump3.png";
// import Jump4Icone from "../../assets/jump4.png";
// import XC20Icon from "../../assets/XC20.png";
// import XC40Icon from "../../assets/XC40.png";
// import BeginnerIcon from "../../assets/beginner.png";
// import ExpertIcon from "../../assets/expert.png";
// import NoviceIcon from "../../assets/novice.png";
// import SportIcon from "../../assets/sport.png";
import { useEffect, useState } from "react";
import axios from "../../axios/index";
import CustomizedMenus from "../../component/menu";
import AddIconModal from "../../component/addIconModal";
import SearchField from "../../component/searchField";
import ScreenLoader from "../../component/screenLoader";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";

// const Icons = [
//   Jump1Icone,
//   Jump2Icone,
//   Jump3Icone,
//   Jump4Icone,
//   XC20Icon,
//   XC40Icon,
//   BeginnerIcon,
//   ExpertIcon,
//   NoviceIcon,
//   SportIcon,
// ];
// const iconData = [
//   { value: "novice", label: "Novice", Icon: NoviceIcon },
//   { value: "beginner", label: "Beginner", Icon: BeginnerIcon },
//   { value: "sport", label: "Sport", Icon: SportIcon },
//   { value: "expert", label: "Expert", Icon: ExpertIcon },
//   { value: "jump 1", label: "Jump 1", Icon: Jump1Icone },
//   { value: "jump 2", label: "Jump 2", Icon: Jump2Icone },
//   { value: "jump 3", label: "Jump 3", Icon: Jump3Icone },
//   { value: "jump 4", label: "Jump 4", Icon: Jump4Icone },
//   { value: "xc20", label: "XC20", Icon: XC20Icon },
//   { value: "xc40", label: "XC40", Icon: XC40Icon },
// ];
const btnStyle = {
  fontSize: "16px",
  fontWeight: "400",
  background: "transparent",
  color: "#000000",
  justifyContent: "start",
  padding: "0px 16px",
  boxShadow: "none",
  opacity: "0.8",
  height: "auto",
  borderRadius: "0",
  "&:hover": {
    background: "transparent",
    boxShadow: "none",
  },
};

// const rows = [
//   {
//     id: 1,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 2,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 3,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 4,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 5,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 6,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 7,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 8,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
//   {
//     id: 9,
//     name: "Eloise Buck",
//     taskName: "Jump 1",
//     verifiedBy: "Angus Booth",
//     dayTime: "30th July 2023 12:00:00 PM",
//   },
// ];

export default function IconChallenge() {
  const [taskData, setTaskData] = useState([]);
  const [RW, setRW] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(""); // State for search query
  const [loader, setLoader] = React.useState(true);

  useEffect(() => {
    axios
      .get("/get-tasks")
      .then((res) => {
        setTaskData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);
  useEffect(() => {
    getRiderWeek();
  }, []);
  const getRiderWeek = () => {
    axios
      .get("/get-rider-of-week")
      .then((res) => {
        setRW(res?.data[0]?.user?._id);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleMakeRider = (id) => {
    axios
      .post("/make-rider-of-week/" + id)
      .then(() => {
        toast.success("Make Rider Of The Week");
        getRiderWeek();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message);
      })
      .finally(() => {});
  };
  const handleRemoveMakeRider = (id) => {
    axios
      .put("/remove-rider-of-week/" + id)
      .then(() => {
        toast.success("Remove Rider Of The Week");
        getRiderWeek();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message);
      })
      .finally(() => {});
  };

  const columns = [
    { field: "user_id", headerName: "User ID", flex: 0 },
    { field: "id", headerName: "No", flex: 0 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "taskName", headerName: "Task Name", flex: 2 },
    { field: "verifiedBy", headerName: "Verified By", flex: 2 },
    { field: "dayTime", headerName: "Day and Time", flex: 2 },
    {
      field: "icons",
      headerName: "Icons",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        let activeIcons = params?.row?.icons;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            {taskData?.length
              ? taskData.map((itm, ind) => {
                  let isFind = activeIcons?.find(
                    (icon) => icon.task == itm?._id
                  );

                  return isFind ? (
                    <Box
                      key={ind}
                      sx={{
                        height: "17px",
                        width: "17px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={`https://admin.raysmtb.com/api/get-file/${itm?.taskIcon?.filename}`}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  ) : null;
                })
              : null}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      sortable: false,

      renderCell: (params) => {
        const options = [
          {
            label: (
              <AddIconModal
                btnText="Add Icon"
                width="100%"
                btnStyle={btnStyle}
                taskIcon={taskData}
                userId={params?.row?.user_id}
                fetchData={fetchData}
                userIcons={params?.row?.icons}
              />
            ),
            cPadding: "0px",
          },
          { label: "Make Rider Of The Week", MRTW: true },
          {
            label: "Remove as Rider Of The Week",
            textColor: "#EA4D3D",
            RRTW: true,
          },
        ];
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CustomizedMenus
              options={options}
              id={params?.row?.user_id}
              handleMakeRider={handleMakeRider}
              handleRemoveMakeRider={handleRemoveMakeRider}
            />
            {params?.row?.user_id === RW ? (
              <Box
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#00B8B9",
                }}
              >
                Rider of the week
              </Box>
            ) : null}
          </Box>
        );
      },
    },
  ];

  var fetchData = async (searchText = "") => {
    axios
      .get(
        `/get-challenges?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchText}`
      )
      .then((res) => {
        // Transform the received data into the desired format
        const transformedData = res?.data?.data?.map((item) => ({
          id: item._id,
          name: `${item?.user?.firstName} ${item?.user?.lastName}`,
          taskName: item?.task?.taskName,
          verifiedBy: `${item?.verifiedBy?.firstName} ${item?.verifiedBy?.lastName}`,
          dayTime: new Date(item?.createdAt).toLocaleString(),
          // status: item?.status,
          user_id: item?.user?._id,
          icons: item?.userIcons,
        }));

        setRows(transformedData);
        setTotalRows(res?.data?.totalRecords);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage?.page);
  };

  // Define a debounce delay (e.g., 300 milliseconds)
  const debounceDelay = 300;

  // Create a debounced search function
  const debouncedSearch = debounce((searchText = "") => {
    fetchData(searchText);
  }, debounceDelay);

  // Handle search input change
  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setPage(0); // Reset to the first page when searching
    setSearchQuery(searchText);
    if (!searchText || searchText == "") {
      debouncedSearch();
    } else if (searchText.length >= 3) {
      debouncedSearch(searchText);
    }
  };

  // Initial data fetch
  React.useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  if (loader) {
    return <ScreenLoader />;
  }
  return (
    <Box
      sx={{
        height: "100vh",
        pt: "198px",
        pb: "150px",
        pl: "40px",
        pr: "45px",
        background: "#fff",
      }}
    >
      <Typography
        sx={{ color: "#081225", fontSize: "32px", fontWeight: 500, mb: "11px" }}
        component="h3"
      >
        Rays Icon Challenge
      </Typography>
      <Box
        sx={{
          mb: "30px",
        }}
      >
        <SearchField value={searchQuery} onChange={handleSearchChange} />
      </Box>
      <div style={{ width: "100%", height: 400 }}>
        <DataGrid
          sx={{
            borderRadius: "20px",
            ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#2F2F2F",
            },
            ".css-19p8xgs-MuiDataGrid-root .MuiDataGrid-cellContent": {
              fontSize: "16px",
              fontWeight: 400,
              color: "#1F1F1F",
            },
            ".MuiDataGrid-withBorderColor": {
              pl: "10px",
            },
            ".MuiDataGrid-cell": {
              pl: "20px !important",
            },
            ".css-s1474d": {
              justifyContent: "flex-start",
              flexWrap: "wrap",
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                user_id: false,
              },
            },
            pagination: {
              paginationModel: { page: page, pageSize: pageSize },
            },
          }}
          rows={rows?.length ? rows : []}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={[10]}
        />
      </div>
    </Box>
  );
}
