import { Box } from "@mui/system";
import InputField from "../inputField/inputField";
import { Button } from "@mui/material";
import { useState } from "react";
import axios from "../../axios/index";
import { toast } from "react-toastify";
import EditEatModal from "../modal/editEatModal";
const FieldGroup = ({
  setNewEat,
  ClearIcon,
  id,
  newEat,
  deleteEat,
  setLetsEat,
}) => {
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [menu, setMenu] = useState("");
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState("");
  const [loader, setLoader] = useState("");

  const addCategory = (id) => {
    if (!company && !address && !phone && !menu) {
      toast.error("Please fill atleast one field");
      return;
    }
    setLoader(true);
    axios
      .post("/add-new-eat-category/" + id, {
        companyName: company,
        address,
        menu,
        phone,
        link
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Successfully Added!");
          setCompany("");
          setMenu("");
          setAddress("");
          setPhone("");
          setLink("");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <Box
      sx={{
        border: "1px solid #C8C8C8",
        borderRadius: "20px",
        padding: "16px 16px",
      }}
    >
      <InputField
        sx={{
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "0px",
            border: "none",
            borderBottom: "1px solid #C8C8C8",
            padding: 0,
          },
          ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
            pl: 0,
            pr: 0,
          },
          ".css-etovqo-MuiInputBase-root-MuiOutlinedInput-root": {
            pl: 0,
            pr: 0,
          },
          ".css-1iokg7v-MuiFormControl-root-MuiTextField-root": {
            pl: 0,
          },
        }}
        Icon={ClearIcon}
        readonly={ClearIcon ? true : false}
        value={newEat}
        onChange={(e) => setNewEat(e?.target?.value)}
        placeholder="Write text...."
        onClick={deleteEat}
      />
      <InputField
        sx={{
          mt: "10px",
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "8px 2px",
          },
        }}
        placeholder="Company Name"
        onChange={(e) => setCompany(e.target.value)}
        value={company}
      />
      <InputField
        sx={{
          mt: "10px",
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "8px 2px",
          },
        }}
        placeholder="Address"
        onChange={(e) => setAddress(e.target.value)}
        value={address}
      />
      <InputField
        sx={{
          mt: "10px",
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "8px 2px",
          },
        }}
        placeholder="Menu"
        onChange={(e) => setMenu(e.target.value)}
        value={menu}
      />
      <InputField
        sx={{
          mt: "10px",
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "8px 2px",
          },
        }}
        placeholder="Phone"
        onChange={(e) => setPhone(e.target.value)}
        value={phone}
      />
      <InputField
        sx={{
          mt: "10px",
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "8px 2px",
          },
        }}
        placeholder="Link"
        onChange={(e) => setLink(e.target.value)}
        value={link}
      />
      <Box
        sx={{
          textAlign: "center",
          mt: "10px",
          display: "flex",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            borderRadius: "10px",
            fontSize: "16px",
            textTransform: "capitalize",
            fontWeight: 400,
            width: "92px",
          }}
          variant="contained"
          onClick={() => addCategory(id)}
          disabled={loader}
        >
          Add
        </Button>
        {ClearIcon ? (
          <EditEatModal id={id} newEat={newEat} setLetsEat={setLetsEat} />
        ) : null}
      </Box>
    </Box>
  );
};

export default FieldGroup;
